<template>
	<div id="app">
		<router-view />

		<!-- ##### Footer Area Start ##### -->
		<footer class="footer-area">
			<!-- Main Footer Area -->
			<div class="main-footer-area">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-6 col-lg-4">
							<div class="footer-widget-area">
								<h4>LINK</h4>
								<dl class="link">
									<dd>
										<a
											href="https://www.facebook.com/ASSIST.KYOTO"
											target="_blank"
											><i class="fab fa-facebook"></i> ASSIST facebook</a
										>
									</dd>
									<dd>
										<a href="https://www.facebook.com/LAPTORR" target="_blank"
											><i id="laptorr-fb-icon" class="fab fa-facebook"></i>
											LAPTORR facebook</a
										>
									</dd>
									<dd>
										<a
											href="http://www.youtube.com/user/ASSISTkyoto881?feature=watch"
											target="_blank"
											><i id="youtube-icon" class="fab fa-youtube"></i>
											YouTubeチャンネル</a
										>
									</dd>
									<dd>
										<a
											href="https://www.instagram.com/assist_kyoto/?hl=ja"
											target="_blank"
											><i id="insta-icon" class="fab fa-instagram"></i>
											Instagram</a
										>
									</dd>
									<dd>
										<a href="http://laptorr.com/" target="_blank"
											><img
												src="/img/common/laptorr.gif"
												alt="LAPTORR"
												style="width: 120px"
										/></a>
									</dd>
								</dl>
							</div>
						</div>

						<!-- Information -->
						<div class="col-12 col-md-6 col-lg-4">
							<div class="footer-widget-area">
								<h4>
									INFORMATION
									<span v-if="information != null" class="badge badge-dark ml-2"
										>{{ getStringFromDate(information.date) }} update</span
									>
								</h4>
								<div v-if="information != null">
									<div
										class="information_post"
										v-html="information.content.rendered"
									></div>
								</div>
							</div>
						</div>

						<!-- News -->
						<div class="col-12 col-md-6 col-lg-4">
							<div class="footer-widget-area">
								<h4>NEWS</h4>
								<div
									v-for="post in news.slice(0, 5)"
									:key="'news_post' + post.id"
									class="news_post"
								>
									<div class="mb-2">
										<a :href="post.link" class="post-title">
											<span class="badge badge-dark mb-1"
												>{{ getStringFromDate(post.date) }} update</span
											>
											<h6>
												{{ post.title.rendered }}
											</h6>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Bottom Footer Area -->
			<div class="bottom-footer-area">
				<div class="container h-100">
					<div class="row h-100 align-items-center">
						<div class="col-12">
							<!-- Copywrite -->
							<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
							Copyright &copy;2020 All rights reserved | This template is made
							with
							<i class="fas fa-heart" aria-hidden="true"></i>
							by
							<a href="https://colorlib.com" target="_blank">Colorlib</a>
							<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
						</div>
					</div>
				</div>
			</div>
		</footer>
		<!-- ##### Footer Area Start ##### -->
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "Event",
	data() {
		return {
			isLoading: true,
			information: null,
			news: [],
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: async function () {
			this.isLoading = true;
			// Information
			axios
				.get("https://blog.assist-kyoto.co.jp/wp-json/wp/v2/pages/2034", {
					params: {},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.information = res.data;
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
			// News
			axios
				.get("https://blog.assist-kyoto.co.jp/wp-json/wp/v2/posts?_embed", {
					params: {
						// お知らせ・更新情報
						categories: [5, 6],
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.news = res.data;
					// simulate AJAX
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
		},
		getStringFromDate: function (date) {
			let d = new Date(date);
			return (
				d.getFullYear() +
				"." +
				("0" + (d.getMonth() + 1)).slice(-2) +
				"." +
				("0" + d.getDate()).slice(-2)
			);
		},
	},
};
</script>

<style lang="scss">
.contact-btn {
	display: inline-block;
	min-width: 150px;
	color: #fff !important;
	border: none;
	border-radius: 5px;
	line-height: 1.6rem;
	padding: 1rem 2rem;
	font-size: 1rem;
	font-weight: 700 !important;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	text-transform: uppercase;
	background: #1976d2;
	background: -webkit-linear-gradient(to right, #1976d2, #19acd2);
	background: linear-gradient(to right, #1976d2, #19acd2);
}
.contact-btn.active,
.contact-btn:hover,
.contact-btn:focus {
	font-size: 1rem;
	font-weight: 700;
	color: #2c3e50 !important;
	background: #2c3e50;
	background: -webkit-linear-gradient(to right, #1976d2, #19acd2);
	background: linear-gradient(to right, #1976d2, #19acd2);
}

.page {
	ul {
		margin-bottom: 2rem;
		li {
			list-style-type: disc;
			margin-left: 1rem;
			padding: 0.4rem;
		}
	}
	ol {
		margin-bottom: 2rem;
		li {
			list-style-type: decimal;
			margin-left: 1rem;
			padding: 0.4rem;
		}
	}
}
/** ヘッダー */
.top-header-area {
	background-color: #152535;
}
.sitename {
	color: #ccc;
	font-size: 0.9rem;
	font-weight: bold;
}
.shopname {
	color: #ccc;
	font-size: 1.2rem;
	font-weight: bold;
	margin-right: 0.6rem;
}
.tel {
	color: #000;
	font-size: 1.2rem;
	font-weight: bold;
}
#breakingNewsTicker {
	li a {
		color: #fff;
	}
}

.table-striped tbody tr:nth-of-type(2n + 1) {
	background-color: #f3f5f8;
}
.block {
	h3 {
		font-size: 1.4rem;
		margin-bottom: 1.6rem;
		padding: 0.2rem 1rem;
		border-left: 4px solid #152535;
	}
}
.topmenu {
	font-size: 0.9rem !important;
	font-weight: 500 !important;
}
.megamenu {
	hr {
		margin: 0.4rem 0;
	}
	.category {
		font-weight: bold;
		padding-bottom: 0.2rem;
	}
}
/** 記事系 */
.posts {
	.post-title {
		h6 {
			margin: 0;
			a {
				color: #152535;
				font-size: 1rem;
			}
		}
		span {
			margin-bottom: 0.6rem;
		}
	}
	.post-text a {
		font-size: 0.7rem;
		font-weight: normal;
	}
}
.footer-area {
	h4 {
		font-size: 1.4rem;
		font-weight: normal;
		margin-bottom: 1.6rem;
		padding: 0.2rem 1rem;
		border-left: 4px double #152535;
		span {
			font-size: 0.7rem;
		}
	}
	.link {
		a {
			color: #000;
		}
	}
	.information_post {
		p {
			color: #000;
		}
	}
	hr {
		display: none;
	}
	.news_post {
		h6 {
			font-size: 0.9rem;
			font-weight: normal;
			color: #000;
		}
	}
}
</style>
